import React from 'react';
import {
  BrowserRouter as Router,
  Routes as RouteProvider,
  Route,
} from 'react-router-dom';
import App from '../App';
import PDP from '../PDP';
import TDC from '../TDC';


import { Container } from './styles';

const Routes = () => {
  return (
    <Container>
      <Router>
        <RouteProvider>
          <Route path="/" exact element={<App />} />
          <Route path="/politica" exact element={<PDP />} />
          <Route path="/termos" exact element={<TDC />} />
        </RouteProvider>
      </Router>
    </Container>
  );
};

export default Routes;
