import React from 'react';
import { Container } from './styles';

const PDP = () => {
  return (
    <Container>
    <h2>Política Privacidade</h2>                    
    <p>A sua privacidade é importante para nós. É política do Republicano
       respeitar a sua privacidade em relação a qualquer informação sua que 
       possamos coletar no app 
       <a href='https://republicano-2ddcb.web.app'>Republicano</a>
       .
    </p>                    
    <p>Solicitamos informações pessoais apenas quando realmente precisamos 
      delas para lhe fornecer um serviço com autenticidade e segurança. 
      Fazemo-lo por meios justos e legais, com o seu conhecimento e consentimento.                     
    </p>                    
    <p>Quando armazenamos dados, protegemos dentro de meios comercialmente aceitáveis 
      ​​para evitar perdas e roubos,bem como acesso, divulgação, cópia, uso ou modificação não autorizados.
    </p>                    
    <p>Não compartilhamos informações de identificação pessoal publicamente ou 
      com terceiros, exceto quando exigido por lei.</p>                                     
    <p>Você é livre para recusar a nossa solicitação de informações pessoais, 
      entendendo que talvez não possamos fornecer os serviços desejados.
    </p>                    
    <p>O uso continuado de nosso app será considerado como aceitação de nossas 
      práticas em torno de privacidade e informações pessoais. Se você tiver alguma 
      dúvida sobre como lidamos com dados do usuário e informações pessoais, 
      entre em contacto connosco.
    </p>                                        
    <h3>Compromisso do Usuário</h3>                                
    <p>O usuário se compromete a fazer uso adequado dos conteúdos e da informação 
      que o Republicano oferece no site e com caráter enunciativo, mas não limitativo:</p>                                        
    <ul>
      <li>A{')'} Não se envolver em atividades que sejam ilegais ou contrárias 
      à boa fé a à ordem pública;</li>                        
      <li>B{')'} Não difundir propaganda ou conteúdo de natureza racista, xenofóbia 
          ou azar, qualquer tipo de pornografia ilegal, de apologia ao 
          terrorismo ou contra os direitos humanos;
      </li>                        
      <li>C{')'} Não causar danos aos sistemas físicos (hardwares) e lógicos 
      (softwares) do Republicano, para introduzir ou disseminar vírus informáticos ou quaisquer outros 
      sistemas de hardware ou software que sejam capazes de causar danos 
      anteriormente mencionados.
      </li>                    
      </ul>                                        
      <h3>Mais informações</h3>                    
      <p>Esperemos que esteja esclarecido e, como mencionado anteriormente, 
        se houver algo que você não tem certeza se precisa ou não, entre em contato conosco.</p>                    
      <p>Esta política é efetiva a partir de <strong>April</strong>/<strong>2022</strong>.</p>
    </Container>
  );
}

export default PDP;