import logo from './ic_launcher.png';
import './App.css';
import { useNavigate } from 'react-router-dom';

function App() {
  const navigate = useNavigate();
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} alt="logo" style={{width: 300, height: 300, marginBottom: 40, borderRadius: 40}}/>
        <div style={{
          flexDirection: 'row',
          display: 'flex',
          width: 300,
          justifyContent: 'space-between'
        }}>

        <button onClick={()=> navigate('/politica')}>
         POLITICA
        </button>
        <button onClick={()=> navigate('/termos')}>
          TERMOS
        </button>
        </div>
      </header>
    </div>
  );
}

export default App;
